.Card.Body {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center content horizontally */
  text-align: center; /* Center text horizontally */
}

.card-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.card-text-container {
  flex-grow: 1;
  display: flex; /* Add display: flex to ensure it takes available space */
  flex-direction: column; /* Stack the text vertically within the container */
  justify-content: center; /* Center the text vertically */
}

.button-container {
  display: flex;
  justify-content: center;
  margin-top: auto;
}

.card-img-top {
  max-height: 100%; /* Ensure the image doesn't exceed the height of its container */
  width: auto; /* Maintain the aspect ratio of the image */
  object-fit: cover; /* Crop the image if it doesn't fit perfectly */
}

.card-title {
  text-align: center !important;
  margin-top: 5px !important;
}