$body-bg: #212529;
$primary: Crimson;
$secondary: Dimgrey;
$light: #D9CB9E;
$warning: #14dc8c;
$info: rgb(207, 128, 54);
*{
    color: $light !important;
}

@import '~bootstrap/scss/bootstrap.scss';
