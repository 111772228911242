// .image-container {
//     display: flex;
//     justify-content: center;
//   }
  
  .card-image {
    max-width: 100px;
    height: auto;
  }

  .modalBtn {
    margin: 10px;
  }